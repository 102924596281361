@import '../../../../scss/variables.scss';

// Map Name

.map-edit-container-map-name {
  color: var(--primary);
}

//Loading
.loading-wrap-edit-map {
  padding-top: 250px;
}

// All
#gateway-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  & span.btn {
    border: none !important;
  }
}

#places-card,
#beacon-card {
  border-radius: 0 !important;

  & span.btn {
    border: none !important;
  }
}

#measurement-card {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  & .card-header {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
}

.edit-maps-group {
  & .form-label {
    color: var(--primary);
  }

  & .floor-input {
    width: 60px;
  }
}

.edit-map-submit-cancel-container {
  gap: 30px;
}

.leaflet-edit-marker-selected {
  background-color: white;
  border: 3px dotted var(--c-primary);
  border-radius: 3px;
}

.edit-map-device-list {
  & li {
    background-color: var(--white);
    border: 1px solid var(--secondary);

    &:hover {
      background-color: var(--white);
    }

    &.list-group-item-action {
      color: var(--dark);

      & .edit-properties-btn {
        color: var(--dark);
      }
    }
  }
}

.save-delete-map-container {
  justify-content: end;
  gap: 25px;
}

.left-edit-map-section {
  & .card-body {
    height: 62vh;
    overflow-y: auto;
  }

  & .card-header-pointer {
    cursor: pointer;
  }

  & .card-header-disabled {
    opacity: 0.6;
  }
}

.min-height-55vh {
  min-height: 55vh;
}

@media (max-width: 1200px) {
  .save-delete-map-container {
    justify-content: center !important;
    margin-top: 25px;
  }
}

// Gateways

.edit-gateway-btns {
  & .btn {
    cursor: pointer;

    &:nth-child(2) {
      color: var(--primary);
    }
  }
}

// Places

.edit-places-btns {
  & .btn {
    cursor: pointer;

    &:nth-child(2) {
      color: var(--primary);
    }
  }
}

// Beacons

.edit-beacons-btns {
  & .btn {
    cursor: pointer;

    &:nth-child(2) {
      color: var(--primary);
    }
  }
}

// Static Measurement

.edit-static-m-btn {
  & .btn {
    color: var(--primary);

    &:hover {
      color: var(--primary);
    }
  }
}
