@import './layout/Dock/Dock.scss';
@import './OnlineStatus/OnlineStatus.scss';

// search.js
.wrap-map-edit-search {
  overflow-y: visible;
  overflow-x: visible;
  height: 0;
}

.map-edit-search-ul {
  min-width: 200px;
  max-height: 300px;
  overflow-x: visible;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
}
