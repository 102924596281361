@import '../../scss/variables.scss';

.onlineStatus {
  display: inline-block;
  width: 10px;
  height: 10px;

  &.onlineStatus-success {
    background-color: var(--primary);
  }

  &.onlineStatus-secondary {
    background-color: var(--secondary);
  }

  &.onlineStatus-warning {
    background-color: var(--warning);
  }

  &.onlineStatus-danger {
    background-color: var(--danger);
  }
}
