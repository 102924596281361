@import '../../scss/variables.scss';

.paginatedSearch-items {
  position: absolute;
  height: 400px;
  width: 100%;
  overflow: auto;
  z-index: 1000;
}

.selectedItem-ClosedSearchTxt {
  color: var(--primary) !important;
}

.selectedItem-ClosedSearchTxt:hover {
  color: var(--primary);
}

.beacon-assign-checkmark-wrapper {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 6px;
}
